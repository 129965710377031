import CarouselMagazine from 'components/carousels/CarouselMagazine'
import { useQuery } from 'urql'
import { isServer } from 'data/helpers/ssr'
import MagazineCarouselSection from './magazine/MagazineCarouselSection'
import { graphql } from 'gql'
import { MagazineArticlesFilterInput } from 'gql/graphql'
import { useMemo } from 'react'

const MAGAZINE_SECTION_ARTICLES_QUERY = graphql(`
query MagazineSectionArticles($filters: MagazineArticlesFilterInput) {
  magazineArticles(first: 9, filters: $filters) {
    data {
      id
      thumbnail {
        src
        alt_text
      }
      categories {
        id
        name
      }
      created_at
      title
      excerpt
      slug
    }
  }
}
`)

interface MagazineSectionProps {
  country?: string
  region?: string
  subregion?: string
}

const MagazineSection = ({ country, region, subregion }: MagazineSectionProps) => {
  const filters: MagazineArticlesFilterInput | undefined = useMemo(
    () => country
      ? {
        country: country,
        region: region,
        subregion: subregion,
      }
      : undefined,
    [country, region, subregion],
  )

  const [{ data, fetching }] = useQuery({
    query: MAGAZINE_SECTION_ARTICLES_QUERY,
    variables: { filters: filters },
    pause: isServer,
  })

  if (isServer || fetching) {
    return <></>
  }

  return (
    <>
      <div className="antialiased my-75 lg:my-[125px] hidden lg:block">
        <CarouselMagazine articles={data?.magazineArticles?.data ?? []} />
      </div>

      <div className="mb-37 lg:hidden">
        <MagazineCarouselSection
          subheading="Our Magazine"
          className={{
            subheading: '!mt-30 !mb-12',
          }}
          filters={filters}
        />
      </div>
    </>
  )
}

export default MagazineSection
